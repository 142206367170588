*:focus {
  outline: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif !important;
  counter-reset: container;
}

footer {
  border-top: 1px solid #e5e5e5;
}

footer a {
  color: #737373 !important;
}

button {
  cursor: pointer;
  color: #ffffff !important;
}

.custom-bg-card {
  background-color: #F3F3F3;
}

.custom-color-primary {
  color: rgb(33, 37, 41) !important;
}

.custom-bg-primary {
  background-color: #0a1d3e !important;
}

.custom-font-size-sm {
  font-size: 0.75rem;
}

h3 span {
  border-radius: 50%;
  display: inline-block;
  font-family: Arial;
  font-size: 12px;
  font-style: italic;
  margin: 0 1.7rem 0 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

h3 span::after {
  content: counter(container);
  counter-increment: container;
  font-size: 50px;
  font-weight: bold;
  text-shadow: 1px 0px 0px #1d2393, -1px 0px 0px #1d2393, 0px 1px 0px #1d2393, 0px -1px 0px #1d2393;
}

div span.ajuda {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #1D2393;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 150%;
}

div span.ajuda::before {
  color: #1D2393;
  content: "?";
  font-family: arial;
  font-size: 1rem;
  font-weight: bold;
  left: 3.5px;
  position: relative;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #2C4593 !important;
  border: #dfdfdf solid 2px !important;
}

.nav-pills .nav-link {
  border: #dfdfdf solid 2px !important;
}

.text-color-nav {
  color: #dfdfdf !important;
}

.card.custom-cardFooter {
  border: none;
  border-radius: 0px;
}

.card.custom-cardHeader a {
  color: #F3F3F3;
}

.card.custom-cardFooter a {
  color: #737373;
}

.card-img.custom-cardLogo {
  width: 153.94px;
  height: 34.86px;
}

.card.custom-cardTitle {
  background-color: transparent;
  border: none;
  margin: 0px;
}

.title {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
}

.header-style {
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.whatsapp-text {
  font-size: 14px;
}

.nextStep {
  background-color: green !important;
  border-radius: 0 !important;
  border: none !important;
}

.nextStep:disabled {
  background-color: rgb(195, 195, 195) !important;
}

@media (min-width: 768px) {
  .title {
    font-size: calc(1.075rem + .3vw);
  }
}

:root {
  --color-primary: #0A1D3E;
  --color-blue: #2C4593;
  --color-violet: #95358B;
  --color-green: #30A96E;
  --color-red: #ff5370;
  --color-yellow: #F3B800;
  --color-black: #333333;
  --color-light-blue: #42a5f5;
  --color-background-white: #f4f4f4;
  --color-alice-blue: #f1f7ff;
  --color-cadet-blue: #b7c0cd;
  --color-azure-blue: #3857ba;
  --color-midnight-blue: #122A56;
  --color-salmon-pink: #ff869a;
}

.whatsapp-text {
  font-size: 14px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

